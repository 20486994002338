import React from 'react';
import Layout from "../components/layout"

const Contact = () => (
  <Layout isExternal withoutFooter>
    <div className="relative h-auto xl:h-screen px-3 md:px-0 xl:pt-24 pb-8">
      <iframe frameBorder="0" id="iframewin" className="w-screen max-w-full" height={typeof window !== "undefined" ? window.innerWidth : "100%"}
              src="https://inzo.maillist-manage.com/ua/Optin?od=11287ecaff6499&zx=129554de3&lD=17f52c15de9947fe&n=11699f74f63befc&sD=17f52c15de994841" />

    </div>
  </Layout>
);

export default Contact;
